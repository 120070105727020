import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import lightbox from 'lightbox2'
gsap.registerPlugin(ScrollTrigger)
import ModalVideo from 'modal-video'
import 'jquery-modal'



/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      autoplay: true,
      dots: true,
      infinite: true,
      prevArrow: $('.page__arrow-prev'),
      nextArrow: $('.page__arrow-next'),
    },
    basicSlider: {
      variableWidth: false,
      centerMode: true,
      autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 832,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    },
    variableSlides: {
      infinite: true,
      variableWidth: true,
      centerMode: false,
      swipeToSlide: true,
    },
    variableNoLoop: {
      infinite: false,
      variableWidth: true,
      centerMode: false,
      swipeToSlide: true,
      arrows: false,
    },
    timelineMain: {
      infinite: true,
      slidesToShow: 3,
      centerMode: true,
      focusOnSelect: true,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      asNavFor: '.page__timeline-content-slider',
      responsive: [{
        breakpoint: 832,
        settings: {
          slidesToShow: 1,
          vertical: false,
          verticalSwiping: false,
        }
      }]
    },
    timelineContent: {
      arrows: false,
      infinite: true,
      vertical: true,
      asNavFor: '.page__timeline-dates-slider',
      responsive: [{
        breakpoint: 832,
        settings: {
          vertical: false,
        }
      }]
    },
    postsPromoted: {
      variableWidth: true,
      centerMode: true,
      infinite: false,
    }
  }

  $el.slick(opts[type])
}

/* LIGHTBOX 2
  ----------------------------- */
export const setupLightbox = () => {
  lightbox.option({
    'resizeDuration': 200,
  })
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

/* MEGA MENU
  ----------------------------- */
export const setupMenu = () => {

  $('.header--main .menu > li').hover(
    function () {
      $('.header--main .menu > li').removeClass('hover-active')
      if ($(this).find('.sub-menu').length > 0) {
        $(this).addClass('hover-active')
      }
    },
    function () {
      
    }
  );

  $('.header--main .menu > li .sub-menu').hover(
    function () {
     
    },
    function () {
      $('.header--main .menu > li').removeClass('hover-active')
    }
  );

  $('.header--main .menu > li .sub-menu li.active').parents('.menu-item').addClass('active active-trail')
}

/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
  const link = $('.header--offcanvas a');
  const header = $('.header--main');

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  // window.onresize = () => {
  //   overlay.removeClass('open')
  //   bar.removeClass('animate')
  //   toggle.removeClass('open')
  // }

  $(document).scrollTop() > 200 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 200 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* SETUP ACCORDION
  ----------------------------- */
export const setupAccordion = () => {
  if ($('.accordion').length === 0) return
  $('.accordion-title').on('click', function () {
    $(this).parent().toggleClass('open')
    $(this).next().slideToggle()
  })
}

/* PRODUCT TICKER
  ----------------------------- */
export const productTicker = () => {
  var ticker = document.querySelector('.product__ticker')
  var titleWidth = ticker.querySelector('span').offsetWidth
  var viewportWidth = window.innerWidth
  
  var totalDistance = titleWidth + viewportWidth
  var speed = totalDistance / 200
  ticker.style.animationDuration = speed + 's'
}

/* PAGE SLIDER COUNTER
  ----------------------------- */
export const pageSliderCounter = () => {
  $('.page__content--e_gallery_slider .page__slider').each(function() {
    $(this).on('afterChange', function(event, slick, currentSlide) {
      const slideId = $(this).find('.slick-current').attr("data-slick-index")
      $(this).next().find('.page__counter-number').text(parseInt(slideId) + 1)
    })
  })
}